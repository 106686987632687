import React, { useState } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../style/index.scss"

const Inizia = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const inviato = params.get("inviato")
  const [modalOn, setModalOn] = useState(inviato === "ok")
  return (
    <Layout page="inizia">
      <Seo title="Inizia la tua terapia" />
      <h2>Inizia la tua terapia e prenota un colloquio</h2>
      <p>
        Compila il modulo per essere contattat* dal dottore e discutere i dettagli del tuo primo incontro. Il dottore ti contatter&agrave; personalmente per organizzare un appuntamento secondo le tue esigenze.
      </p>
      <div id="form-wrapper">
        <form
          action="https://us-central1-jacopo-biraschi-psicologo.cloudfunctions.net/inizia"
          method="POST"
        >
          <div className="form-field">
            <label htmlFor="name">Nome*</label>
            <input
              className="flex-input"
              type="text"
              name="name"
              id="name"
              pattern="[\w\s]{3,20}"
              required
            ></input>
          </div>
          <div className="input-tip-right">Usare solo lettere (min. 3 - max. 20)</div>
          <div className="form-field">
            <label htmlFor="email">Email*</label>
            <input
              className="flex-input"
              type="email"
              name="email"
              id="email"
              required
            ></input>
          </div>
          <div className="form-field">
            <label htmlFor="phone">Telefono</label>
            <input
              className="flex-input"
              type="phone"
              name="phone"
              id="phone"
              pattern="(\+|00)\d*"
            ></input>
          </div>
          <div className="input-tip-right">Usare il formato +39 o 0039</div>
          <div className="form-field">
            <label htmlFor="phone">Messaggio</label>
            <textarea
              className="flex-input"
              name="message"
              id="message"
            ></textarea>
          </div>
          <div className="input-tip-right">* campi obbligatori</div>
          <input type="submit" value="Invia la Richiesta" />
          <div className="input-tip">
            Cliccando "Invia la Richiesta" si accetta il trattamento dei dati personali
          </div>
        </form>
      </div>
      <section className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.7213878503667!2d9.206510789026474!3d45.47541609649107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4786c6eab246e519%3A0xad3561c12e8eeb79!2sVia%20Bernardino%20Ramazzini%2C%204%2C%2020129%20Milano%20MI%2C%20Italia!5e0!3m2!1sno!2sno!4v1715264135928!5m2!1sno!2sno"
          allowfullscreen="false"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
      {modalOn && (
        <div id="modal" onClick={() => {
          setModalOn(false)
        }}>
          <div id="modal-content">
            <h2>Grazie per averci contattato!</h2>
            <div>
              Il Dott. Biraschi ti contatter&agrave; nelle prossime 24 ore per
              confermare il tuo appuntamento.
            </div>
            <button
              onClick={() => {
                setModalOn(false)
              }}
            >
              Va bene
            </button>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Inizia
